import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Container, Row, Col } from "react-bootstrap"

import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../../Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import RelatedNews from "@Components/StaticPageTwo/RelatedNews/RelatedNews"
import logoBlack from "../../images/logo.svg"
import NewsBanner from "@Components/Static/NewsBanner/NewsBanner"
import ContactSidebarNews from "@Components/Static/ContactSidebar/ContactSidebarNews"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const BlogDetails = props => {
  const GET_BLOG_DETAILS = gql`
    query GetBLog($URL: String!) {
      blogs(where: { URL: $URL }) {
        id
        imagetransforms
        Video_Url
        Banner_Content
        Add_Content {
          Content
          Add_Image {
            Caption
            Image {
              alternativeText
              url
            }
          }
        }
        Banner_Image {
          alternativeText
          url
        }
        Choose_Team {
          id
          imagetransforms
          Designation
          Name
          Phone
          Email
          Image {
            alternativeText
            url
          }
        }
        Date
        Meta_Description
        Meta_Title
        Publish
        Title
        URL
        blog_categories {
          Category_Name
        }
        Tail_Image {
          alternativeText
          url
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_BLOG_DETAILS, {
    variables: { URL: props.slug },
  })
  let shareurl = ""

  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  return data?.blogs?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout
                className="blog-page"
                Alias={"latest-property-news-and-insights"}
              >
                {data?.blogs?.map((Page, i) => {
                  let processedImages = JSON.stringify({})
                  if (Page?.imagetransforms?.Banner_Image_Transforms) {
                    processedImages = Page.imagetransforms.Banner_Image_Transforms
                  }
                  return (
                    <>
                      <SEO
                        title={Page ? Page.Title : ""}
                        description={
                          Page
                            ? "Read about " +
                              Page.Title +
                              " here and subscribe to our newsletter to stay up-to-date about everything going on at DNG Estate Agents."
                            : ""
                        }
                        image={
                          Page?.Banner_Image?.url ? Page.Banner_Image.url : null
                        }
                      />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template news-list`,
                        }}
                      />
                      <Breadcrumbs
                        Page={Page?.Title}
                        class="static"
                        alias={"latest-property-news-and-insights"}
                        type="details-page"
                      />
                      <NewsBanner
                        Banner_Image={Page?.Banner_Image}
                        Banner_Content={Page?.Banner_Content}
                        Banner_Title={Page?.Title}
                        Banner_Type={Page?.Banner_Type}
                        Date={Page?.Date}
                        Category={Page?.blog_categories}
                        processedImages={processedImages}
                        id={Page?.id}
                      />
                      <div className="static-block">
                        <Container>
                          <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <motion.div className="ContentBlock">
                                {Page?.Add_Content.map((item, j) => {
                                  return (
                                    <>
                                      <ReactMarkdown
                                        source={item.Content}
                                        allowDangerousHtml
                                      />
                                      {item.Add_Image.map((list, k) => {
                                        return (
                                          <>
                                            <div className="img-bk">
                                              <img
                                                alt={
                                                  list.Image?.alternativeText
                                                    ? list.Image
                                                        ?.alternativeText
                                                    : Page?.Title +
                                                      " - DNG Estate Agents"
                                                }
                                                src={list.Image?.url}
                                              />
                                              <p>{list.Caption}</p>
                                            </div>
                                          </>
                                        )
                                      })}
                                    </>
                                  )
                                })}
                              </motion.div>
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                              <div className="sidebar-bk">
                                <ContactSidebarNews
                                  Choose_Team={Page.Choose_Team}
                                />
                                <OpinionList />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </>
                  )
                })}
                <SubscribeForm type="News" />
                {props.slug && <RelatedNews CurrentNews={props.slug} />}
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
}

export default BlogDetails
